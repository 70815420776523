import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = ({ data }) => {
    return (
    <Layout>
      <SEO
        title={data.wordpressPage.title}
        description={data.wordpressPage.excerpt}
      />
      <main>
        <section className="pages">
        <div className="content">
        {data.allWordpressPage.edges.map((post, index) =>(
          <div>
              <div dangerouslySetInnerHTML={{ __html: post.node.content }} />
          </div>
        ))}
      </div>
        </section> 
      </main>
     
    </Layout>
  )}
  export default PageTemplate

  export const query = graphql`
  query ($id: Int!){
    allWordpressPage (filter: {wordpress_id: {eq: $id}}) {
      edges {
        node {
            title
            slug
            content
        }
      }
    }
    wordpressPage(wordpress_id: {eq: $id}) {
        title
        excerpt
      }
  }
`